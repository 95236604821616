/*font families*/
/*outfit*/
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Regular.woff2') format('woff2'),    url('./assets/fonts/Outfit-Regular.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Medium.woff2') format('woff2'),    url('./assets/fonts/Outfit-Medium.woff') format('woff');font-weight: 500;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-ExtraBold.woff2') format('woff2'),    url('./assets/fonts/Outfit-ExtraBold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Bold.woff2') format('woff2'),    url('./assets/fonts/Outfit-Bold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Light.woff2') format('woff2'),    url('./assets/fonts/Outfit-Light.woff') format('woff');font-weight: 300;font-style: normal;font-display: swap;}
@font-face {font-family: 'Outfit';src: url('./assets/fonts/Outfit-Black.woff2') format('woff2'),    url('./assets/fonts/Outfit-Black.woff') format('woff');font-weight: 900;font-style: normal;font-display: swap;}
/*inter font*/
@font-face {font-family: 'Inter';src: url('./assets/fonts/Inter-SemiBold.woff2') format('woff2'),    url('./assets/fonts/Inter-SemiBold.woff') format('woff');font-weight: 600;font-style: normal;font-display: swap;}
@font-face {font-family: 'Inter';src: url('./assets/fonts/Inter-Regular.woff2') format('woff2'),    url('./assets/fonts/Inter-Regular.woff') format('woff');font-weight: normal;font-style: normal;font-display: swap;}
@font-face {font-family: 'Inter';src: url('./assets/fonts/Inter-Medium.woff2') format('woff2'),    url('./assets/fonts/Inter-Medium.woff') format('woff');font-weight: 500;font-style: normal;font-display: swap;}
@font-face {font-family: 'Inter';src: url('./assets/fonts/Inter-Bold.woff2') format('woff2'),    url('./assets/fonts/Inter-Bold.woff') format('woff');font-weight: bold;font-style: normal;font-display: swap;}
/*chatbox*/
.chat-box-section .chat-sidebar::-webkit-scrollbar {background-color: #fff ; width: 10px;}
.chat-box-section .chat-sidebar::-webkit-scrollbar-button { background-color: #fff ; color: #fff ; }
.chat-box-section .chat-sidebar::-webkit-scrollbar-track  { background-color:#fff ; }
.chat-box-section .chat-sidebar::-webkit-scrollbar-track-piece  { background-color: #fff }
.chat-box-section .chat-sidebar::-webkit-scrollbar-thumb  {  background:  #044284; }
.chat-box-section .chat-sidebar::-webkit-scrollbar-corner { background-color:#fff ;  }
.chat-box-section .chat-sidebar::-webkit-resizer {background-color:#fff ; }
.chat-box-section .chat-sidebar::-webkit-scrollbar-button:single-button {background-color: #fff ;display: block;border-style: solid;height: 15px;width: 10px;}
.chat-box-section .chat-sidebar::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #044284 transparent;}
.chat-box-section .chat-sidebar::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #044284 transparent transparent transparent;}
.chat-box-section .chat-sidebar{scrollbar-color: #044284 #fff  !important; scrollbar-width: thin;}
/*users-section .search-list*/
.users-section .search-list::-webkit-scrollbar {background-color: #fff ; width: 10px;}
.users-section .search-list::-webkit-scrollbar-button { background-color: #fff ; color: #fff ; }
.users-section .search-list::-webkit-scrollbar-track  { background-color:#fff ; }
.users-section .search-list::-webkit-scrollbar-track-piece  { background-color: #fff }
.users-section .search-list::-webkit-scrollbar-thumb  {  background:  #044284; }
.users-section .search-list::-webkit-scrollbar-corner { background-color:#fff ;  }
.users-section .search-list::-webkit-resizer {background-color:#fff ; }
.users-section .search-list::-webkit-scrollbar-button:single-button {background-color: #fff ;display: block;border-style: solid;height: 15px;width: 10px;}
.users-section .search-list::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #044284 transparent;}
.users-section .search-list::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #044284 transparent transparent transparent;}
.users-section .search-list{scrollbar-color: #044284 #fff  !important; scrollbar-width: thin;}
/**/
.multi-select .dropdown-content .options::-webkit-scrollbar {background-color: #fff ; width: 10px;}
.multi-select .dropdown-content .options::-webkit-scrollbar-button { background-color: #fff ; color: #fff ; }
.multi-select .dropdown-content .options::-webkit-scrollbar-track  { background-color:#fff ; }
.multi-select .dropdown-content .options::-webkit-scrollbar-track-piece  { background-color: #fff }
.multi-select .dropdown-content .options::-webkit-scrollbar-thumb  {  background:  #044284; }
.multi-select .dropdown-content .options::-webkit-scrollbar-corner { background-color:#fff ;  }
.multi-select .dropdown-content .options::-webkit-resizer {background-color:#fff ; }
.multi-select .dropdown-content .options::-webkit-scrollbar-button:single-button {background-color: #fff ;display: block;border-style: solid;height: 15px;width: 10px;}
.multi-select .dropdown-content .options::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #044284 transparent;}
.multi-select .dropdown-content .options::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #044284 transparent transparent transparent;}
.multi-select .dropdown-content .options{scrollbar-color: #044284 #fff  !important; scrollbar-width: thin;}
/**/
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar {background-color: #fff ; width: 10px;}
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-button { background-color: #fff ; color: #fff ; }
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-track  { background-color:#fff ; }
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-track-piece  { background-color: #fff }
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-thumb  {  background:  #044284; }
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-corner { background-color:#fff ;  }
.chat-box-section .rce-container-mlist.message-list::-webkit-resizer {background-color:#fff ; }
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-button:single-button {background-color: #fff ;display: block;border-style: solid;height: 15px;width: 10px;}
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #044284 transparent;}
.chat-box-section .rce-container-mlist.message-list::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #044284 transparent transparent transparent;}
.chat-box-section .rce-container-mlist.message-list{scrollbar-color: #044284 #fff  !important; scrollbar-width: thin;}
/**/
.noti-modal .body::-webkit-scrollbar {background-color: #044284; width: 10px;}
.noti-modal .body::-webkit-scrollbar-button { background-color: #044284; color: #F4F5FA ; }
.noti-modal .body::-webkit-scrollbar-track  { background-color:#044284; }
.noti-modal .body::-webkit-scrollbar-track-piece  { background-color: #044284}
.noti-modal .body::-webkit-scrollbar-thumb  {  background:  #FCEA04; }
.noti-modal .body::-webkit-scrollbar-corner { background-color:#044284;  }
.noti-modal .body::-webkit-resizer {background-color:#044284; }
.noti-modal .body::-webkit-scrollbar-button:single-button {background-color: #044284;display: block;border-style: solid;height: 15px;width: 10px;}
.noti-modal .body::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #FCEA04 transparent;}
.noti-modal .body::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #FCEA04 transparent transparent transparent;}
.noti-modal .body{scrollbar-color: #FCEA04 hsl(211, 94%, 27%) !important; scrollbar-width: thin;}
/*login-page*/
.login-page::-webkit-scrollbar {background-color: #044284; width: 10px;}
.login-page::-webkit-scrollbar-button { background-color: #044284; color: #F4F5FA ; }
.login-page::-webkit-scrollbar-track  { background-color:#044284; }
.login-page::-webkit-scrollbar-track-piece  { background-color: #044284}
.login-page::-webkit-scrollbar-thumb  {  background:  #FCEA04; }
.login-page::-webkit-scrollbar-corner { background-color:#044284;  }
.login-page::-webkit-resizer {background-color:#044284; }
.login-page::-webkit-scrollbar-button:single-button {background-color: #044284;display: block;border-style: solid;height: 15px;width: 10px;}
.login-page::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #FCEA04 transparent;}
.login-page::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #FCEA04 transparent transparent transparent;}
.login-page{scrollbar-color: #FCEA04 hsl(211, 94%, 27%) !important; scrollbar-width: thin;}
/**/
.sidebar .main-menu::-webkit-scrollbar {background-color: #044284; width: 10px;}
.sidebar .main-menu::-webkit-scrollbar-button { background-color: #044284; color: #F4F5FA ; }
.sidebar .main-menu::-webkit-scrollbar-track  { background-color:#044284; }
.sidebar .main-menu::-webkit-scrollbar-track-piece  { background-color: #044284}
.sidebar .main-menu::-webkit-scrollbar-thumb  {  background:  #FCEA04; }
.sidebar .main-menu::-webkit-scrollbar-corner { background-color:#044284;  }
.sidebar .main-menu::-webkit-resizer {background-color:#044284; }
.sidebar .main-menu::-webkit-scrollbar-button:single-button {background-color: #044284;display: block;border-style: solid;height: 15px;width: 10px;}
.sidebar .main-menu::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #FCEA04 transparent;}
.sidebar .main-menu::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #FCEA04 transparent transparent transparent;}
.sidebar .main-menu{scrollbar-color: #FCEA04 #044284 !important; scrollbar-width: thin;}
/*content-area*/
.content-area::-webkit-scrollbar {background-color: #F4F5FA ; width: 10px;}
.content-area::-webkit-scrollbar-button { background-color: #F4F5FA ; color: #F4F5FA ; }
.content-area::-webkit-scrollbar-track  { background-color:#F4F5FA ; }
.content-area::-webkit-scrollbar-track-piece  { background-color: #F4F5FA }
.content-area::-webkit-scrollbar-thumb  {  background:  #044284; }
.content-area::-webkit-scrollbar-corner { background-color:#F4F5FA ;  }
.content-area::-webkit-resizer {background-color:#F4F5FA ; }
.content-area::-webkit-scrollbar-button:single-button {background-color: #F4F5FA ;display: block;border-style: solid;height: 15px;width: 10px;}
.content-area::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #044284 transparent;}
.content-area::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #044284 transparent transparent transparent;}
.content-area{scrollbar-color: #044284 #F4F5FA  !important; scrollbar-width: thin;}
/*progress-responsive*/
.progress-responsive::-webkit-scrollbar {background-color: #F4F5FA; height: 8px; }
.progress-responsive::-webkit-scrollbar-button { background-color: #F4F5FA; height: 8px;width: 10px;}
.progress-responsive::-webkit-scrollbar-track  { background-color:#F4F5FA; }
.progress-responsive::-webkit-scrollbar-track-piece  { background-color: #F4F5FA;}
.progress-responsive::-webkit-scrollbar-thumb  {  background: #044284; }
.progress-responsive::-webkit-scrollbar-corner { background-color:#F4F5FA;  }
.progress-responsive::-webkit-resizer {background-color:#F4F5FA; }
.progress-responsive{scrollbar-color:  #044284 #F4F5FA ;scrollbar-width: thin;}
.progress-responsive::-webkit-scrollbar-button:single-button {background-color: #F4F5FA;display: block;border-style: solid;height: 8px;width: 10px;}
.progress-responsive::-webkit-scrollbar-button:horizontal:start{border-width: 4px 4px 4px 0px;border-color: transparent #044284 transparent transparent;}
.progress-responsive::-webkit-scrollbar-button:horizontal:end{border-width: 4px 0px 4px 4px;border-color: transparent transparent transparent #044284;}
/*table responsive*/
.table-responsive::-webkit-scrollbar {background-color: #F4F5FA; height: 8px; }
.table-responsive::-webkit-scrollbar-button { background-color: #F4F5FA; height: 8px;width: 10px;}
.table-responsive::-webkit-scrollbar-track  { background-color:#F4F5FA; }
.table-responsive::-webkit-scrollbar-track-piece  { background-color: #F4F5FA;}
.table-responsive::-webkit-scrollbar-thumb  {  background: #044284; }
.table-responsive::-webkit-scrollbar-corner { background-color:#F4F5FA;  }
.table-responsive::-webkit-resizer {background-color:#F4F5FA; }
.table-responsive{scrollbar-color:  #044284 #F4F5FA ;scrollbar-width: thin;}
.table-responsive::-webkit-scrollbar-button:single-button {background-color: #F4F5FA;display: block;border-style: solid;height: 8px;width: 10px;}
.table-responsive::-webkit-scrollbar-button:horizontal:start{border-width: 4px 4px 4px 0px;border-color: transparent #044284 transparent transparent;}
.table-responsive::-webkit-scrollbar-button:horizontal:end{border-width: 4px 0px 4px 4px;border-color: transparent transparent transparent #044284;}
/*.main-kpi .graph-area*/
.graph-area::-webkit-scrollbar {background-color: #F4F5FA; height: 8px; }
.graph-area::-webkit-scrollbar-button { background-color: #F4F5FA; height: 8px;width: 10px;}
.graph-area::-webkit-scrollbar-track  { background-color:#F4F5FA; }
.graph-area::-webkit-scrollbar-track-piece  { background-color: #F4F5FA;}
.graph-area::-webkit-scrollbar-thumb  {  background: #044284; }
.graph-area::-webkit-scrollbar-corner { background-color:#F4F5FA;  }
.graph-area::-webkit-resizer {background-color:#F4F5FA; }
.graph-area{scrollbar-color:  #044284 #F4F5FA ;scrollbar-width: thin;}
.graph-area::-webkit-scrollbar-button:single-button {background-color: #F4F5FA;display: block;border-style: solid;height: 8px;width: 10px;}
.graph-area::-webkit-scrollbar-button:horizontal:start{border-width: 4px 4px 4px 0px;border-color: transparent #044284 transparent transparent;}
.graph-area::-webkit-scrollbar-button:horizontal:end{border-width: 4px 0px 4px 4px;border-color: transparent transparent transparent #044284;}
/**/
html {overflow: hidden;scroll-behavior: smooth;}
body {font-family: 'Inter' !important;overflow: hidden;background-color: #F4F5FA !important;}
/*input-file-btn-holder*/
.input-file-btn-holder {position: relative;}
.input-file-btn-holder label {display: inline-block;background-color: #044284;color: white;padding: 12px 30px;border-radius: 0.3rem;cursor: pointer;margin-top: 1rem;margin: 0;width: 278px !important;text-align: center;font-size: 14px !important;font-weight: 500 !important;}
/*custom checkbox*/
.check-container {display: block;position: relative;padding-left: 35px;margin-bottom: 20px;cursor: pointer;font-size: 15px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;font-weight: 500;width: auto !important;}
.check-container input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
.check-container input ~ .checkmark {position: absolute;top: 0;left: 0;height: 20px;width: 20px;background-color: #fff;border-radius: 4px;border: 1px solid #E3E7F0;}
.check-container input:checked ~ .checkmark {background-color: #2196F3;}
.check-container input ~ .checkmark:after {content: "";position: absolute;display: none;}
.check-container input:checked ~ .checkmark:after {display: block;}
.check-container .checkmark:after {left: 6px;top: 1px;width: 7px;height: 13px;border: solid #FCEA04;border-width: 0 3px 3px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}
/*custom switch*/
.switch {position: relative;display: inline-block;width: 60px;height: 34px;}
.switch input {opacity: 0;width: 0;height: 0;}
.switch .slider {position: absolute;top: 0;left: 0;right: 0;bottom: 0;background-color: #fff;transition: .4s;border: 1px solid #E3E7F0;}
.switch .slider:before {position: absolute;content: "";height: 26px;width: 26px;left: 4px;bottom: 3px;background-color: #8C97A2;-webkit-transition: .4s;transition: .4s;}
.switch input:checked + .slider {background-color: #2196F3;border: 1px solid #044284;}
.switch input:focus + .slider {box-shadow: 0 0 1px #2196F3;}
.switch input:checked + .slider:before {-webkit-transform: translateX(26px);-ms-transform: translateX(26px);transform: translateX(26px);background-color: #FFB400;}
.switch .slider.round {border-radius: 34px;}
.switch .slider.round:before {border-radius: 50%;}
.switch .name{padding-left: 80px;margin-top: -9px;white-space: nowrap;color: #8C97A2; font-size: 14px;}
.switch .name.inactive {display: block !important;}
.switch .name.active {display: none !important;}
.switch input:checked ~ .name.inactive {display: none !important;}
.switch input:checked ~ .name.active {display: block !important;}
.switch .disable-switch {
    cursor: not-allowed !important;
}
.switch .enable-switch {
    cursor: pointer !important;
}
/**/
.swal2-confirm.swal2-styled {border-radius: 6px;display: flex;justify-content: center;align-items: center;background-color: #fff !important;border: 1px solid #fff !important;color: #044284;padding: 10px 16px;font-size: 14px; font-weight: 500;text-decoration: none;}
.swal2-confirm.swal2-styled:hover {color: #044284;background-color: #fff;}
/**/
.swal2-confirm.swal2-styled:disabled, .swal2-confirm.swal2-styled[disabled] {cursor: not-allowed;background: grey;border: 1px solid grey;}
.swal2-confirm.swal2-styled:disabled:hover, .swal2-confirm.swal2-styled[disabled]:hover{background: grey;border: 1px solid grey;color: #fff;}
/**/
.blue-button {border-radius: 6px;display: flex;justify-content: center;align-items: center;background-color: #044284;border: 1px solid #044284;color: #fff;padding: 10px 16px;font-size: 14px; font-weight: 500;}
.blue-button:hover {color: #044284;background-color: #fff;font-size: 14px; font-weight: 500;}
/**/
.disable-button {border-radius: 6px;display: flex;justify-content: center;align-items: center;background-color: #044284;border: 1px solid #044284;color: #fff;padding: 10px 16px;font-size: 14px;font-weight: 500;cursor: pointer;}
/*disabled button*/
.disable-button:disabled, .disable-button[disabled] {cursor: not-allowed;background: grey;border: 1px solid grey;}
.disable-button:disabled:hover, .disable-button[disabled]:hover{background: grey;border: 1px solid grey;color: #fff;}
/**/
.blue-outline-button {border-radius: 6px;display: flex;justify-content: center;align-items: center;background-color: #fff;border: 1px solid #044284;color: #044284;padding: 10px 16px;font-size: 14px; font-weight: 500;text-decoration: none;}
.blue-outline-button:hover {color: #fff;background-color: #044284;}
/*disabled button*/
.blue-button:disabled, .blue-button[disabled] {cursor: not-allowed;background: grey;border: 1px solid grey;}
.blue-button:disabled:hover, .blue-button[disabled]:hover{background: grey;border: 1px solid grey;color: #fff;}
/**/
.blue-outline-button:disabled, .blue-outline-button[disabled] {cursor: not-allowed;background: transparent;border: 1px solid grey; color: grey;}
.blue-outline-button:disabled:hover, .blue-outline-button[disabled]:hover{background: transparent;border: 1px solid grey; color: grey;}
/****/
.text-danger {font-size: 12px;}
/**/
.alert-info {--bs-alert-color: #fff !important;--bs-alert-bg: #044284 !important;--bs-alert-border-color: #044284 !important;margin-bottom: 0 !important;font-size: 16px;font-weight: bold;}
/*react slect*/
.react-select__control .react-select__indicator-separator {display: none;}
.react-select__menu .react-select__option{text-align: start;}
.react-select__menu .react-select__option.react-select__option--is-selected.react-select__option--is-focused{background-color: #044284;color: #FCEA04;}
.react-select__menu .react-select__option.react-select__option--is-selected{background-color: #044284;color: #FCEA04;}
.react-select__menu .react-select__option.react-select__option--is-focused{background-color: #4299F5;color: #fff;}
/*rc-slider*/
.rc-slider .rc-slider-rail {position: absolute;width: 100%;height: 20px;background-color: #F4F7FC;border-radius: 10px;}
.rc-slider .rc-slider-track {position: absolute;height: 20px;background-color: #6FD226;border-radius: 10px 0px 0px 10px;}
.rc-slider .rc-slider-dot {display: none;}
.rc-slider .rc-slider-handle {width: 10px;height: 33px;margin-top: -13px;background-color: #0C0D0F;border: none;border-radius: 0;opacity: 1;}
.rc-slider .rc-slider-mark {position: static;width: 100%;font-size: 16px;font-weight: 600;display: flex;justify-content: space-between;margin-top: 35px;margin-left: 10px;}
.rc-slider .rc-slider-mark  .rc-slider-mark-text{color: #0C0D0F;}
.rc-slider .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {border-color: #FCEA04 ;box-shadow: 0 0 0 5px #FCEA04 ;}
.rc-slider .rc-slider-mark-text{position: static;}
/**/
.content-area {width: calc(100% - 292px);padding: 30px 18px;overflow-y: auto;height: 100vh;overflow-x: hidden;}
/*add form area*/
.add-form {max-width: 100%;margin: 0 auto;}
.add-form .form-gapping {margin: 0 0 30px;}
.add-form label{display: block;margin: 10px 0 10px;font-weight: 400;font-size: 16px;line-height: 20px;color: #5D6B7A;width: 150px;}
.add-form label.checkbox{width: auto;}
.add-form label.text-danger {margin-bottom: 0;width: auto;}
.add-form input {height: 50px;background-color: #F4F7FC;border: 1px solid #E3E7F0;border-radius: 8px;margin-left: 20px !important;width: calc(100% - 170px);}
.add-form textarea {height: 150px;background-color: #F4F7FC;border: 1px solid #E3E7F0;border-radius: 8px;resize: none;margin-left: 20px !important;width: calc(100% - 170px);}
.add-form textarea:focus {outline: 0;box-shadow: none;background-color: #F4F7FC;border: 1px solid #2684ff;}
.add-form input:focus {outline: 0;box-shadow: none;background-color: #F4F7FC;border: 1px solid #2684ff;}
.add-form button, .add-form a{min-width: 150px;}
.add-form .react-select {margin-left: 20px !important;width: calc(100% - 170px);}
.add-form .react-select .react-select__control {height: 50px;background-color: #F4F7FC;border: 1px solid #E3E7F0;border-radius: 8px;text-align: start;}
.add-form .react-select .react-select__input-container{height: 45px;}
.add-form .react-select .react-select__input-container input{box-shadow: none !important;height: auto; margin-left: 0 !important;}
.add-form .react-select .react-select__input-container input:focus{box-shadow: none !important;}
.add-form .checkbox input {height: auto;background-color: #fff;border: none;box-shadow: none;width: auto;}
.add-form .add-form-buttons {margin-top: 50px;}
.add-form .permission-check-all {margin: 0 0 20px;}
/*dashboard pages*/
.dashboard .graph-head{font-size: 22px;line-height: 26px;font-weight: 600;}
.dashboard .sub-heading{font-size: 16px;font-weight: normal;line-height: 20px;color: #8C97A2;}
.dashboard .sub-heading-bold{font-size: 16px;font-weight: bold;line-height: 20px;color: #8C97A2;}
.dashboard .sub-heading-medium{font-size: 16px;font-weight: 500;line-height: 20px;color: #8C97A2;}
.dashboard .small-tag{font-size: 14px;font-weight: 500;line-height: 18px;color: #8C97A2;}
.dashboard .green {color: #6FD226 !important;}
.dashboard .red {color: #FF4C51 !important;}
.dashboard .section-margin{margin-bottom:30px;}
.dashboard .section-basic {width: 100%;border: none;box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);border-radius: 8px;background: #fff;}
.dashboard .section-padding  {padding: 20px;}
/*incentive*/
.dashboard .incentive-earned {display: flex;}
.dashboard .incentive-earned .graph-area{width: 60%;padding: 30px;}
.dashboard .incentive-earned .graph-head{margin: 0 0 30px;}
.dashboard .incentive-earned .graph-report{width: 40%;border-left: 1px solid #E3E7F0;padding: 30px;}
.dashboard .incentive-earned .graph-area canvas{width: 100% !important;height: 310px !important;}
.dashboard .incentive-earned .graph-report .target{margin: 0 0 30px;}
.dashboard .incentive-earned .graph-report .target .target-value{font-size: 22px;font-weight: 600;line-height: 26px;margin: 0 0 10px;display: block;}
.dashboard .incentive-earned .graph-report .percent-target{margin: 0 0 30px;}
.dashboard .incentive-earned .graph-report .percent-target .target-img{width: 44px;height: 44px;border-radius: 8px;display: flex;justify-content: center;align-items: center;font-size: 20px;font-weight: bold;}
.dashboard .incentive-earned .graph-report .percent-target .target-img.green{background-color: rgba(111, 210, 38, 0.2);color: #6FD226;}
.dashboard .incentive-earned .graph-report .percent-target .target-img.blue{background-color: rgba(4, 66, 132, 0.2);color: #044284;}
.dashboard .incentive-earned .graph-report .percent-target .target-new{max-width: calc(100% - 60px);margin-left: 16px;}
.dashboard .incentive-earned .graph-report .percent-target .target-new .target-value{font-size: 18px;font-weight: 600;line-height: 22px;margin: 0 0 5px;display: block;}
.dashboard .incentive-earned .graph-report .percent-target .target-new .target-tag{font-size: 16px;font-weight: bold;line-height: 20px;color: #8C97A2;}
.dashboard .incentive-earned .report-button{height: 42px;width: 100%;border-radius: 6px;display: flex;justify-content: center;align-items: center;background-color: #044284;border: 1px solid #044284;color: #fff;}
.dashboard .incentive-earned .report-button:hover{color: #044284;background-color: #fff;}
.dashboard .incentive-earned .graph-report .percent-target.last-target{margin: 0 0 70px;}
/*sales performance*/
.dashboard .sales-performance {display: flex;justify-content: space-between;margin: 0 0 30px;}
.dashboard .sales-performance .graph-head {margin: 0 0 30px; display: block;}
.dashboard .sales-performance .small-tag {margin: 0 0 10px;display: inline-block;}
.dashboard .sales-performance .cal-performance{font-size: 24px;line-height: 28px;font-weight: 600;}
.dashboard .sales-performance .cal-value{font-size: 13px;line-height: 17px;font-weight: 600;}
.dashboard .sales-performance  canvas{left: -30px !important;top: -18px !important;width: 160px !important;height: 160px !important;}
/*response time*/
.dashboard .response-time .graph-head { display: block;margin: 0 0 8px;}
.dashboard .response-time .small-tag {margin-bottom: 12px;display: block;}
.dashboard .response-time .cal-performance{font-size: 24px;line-height: 28px;font-weight: 600;}
.dashboard .response-time .cal-value{font-size: 13px;line-height: 17px;font-weight: 600;}
.dashboard .response-time canvas{left: auto !important;bottom: -90px !important;right: 0 !important;top: auto !important;width: 300px !important;height: 240px !important;}
/*activity tracking*/
.dashboard .activity-tracking .graph-head { display: block;margin: 0 0 30px;}
.dashboard .activity-tracking .activity-data{margin: 0 0 30px;padding-left: 25px;position: relative;}
.dashboard .activity-tracking .activity-data:last-of-type{margin: 0 0 26px;}
.dashboard .activity-tracking .activity-data::before{content:"";position: absolute;width: 6px;left: 0;top: 9px;bottom: 6px;}
.dashboard .activity-tracking .activity-data.green::before{background-color:  #6FD226;}
.dashboard .activity-tracking .activity-data.blue::before{background-color:  #044284;}
.dashboard .activity-tracking canvas{left: -15px !important;top: -35px !important;width: 251px !important;height: 200px !important;}
/*metro-table*/
.metro-table .section-head { display: block;margin: 0 0 30px;}
.metro-table .semi-bold {font-weight: 600;}
.metro-table .medium {font-weight: 500;}
.metro-table .table {--bs-table-striped-bg: #F4F7FC !important;--bs-table-hover-bg: rgba(4, 66, 132, 0.2) !important;}
.metro-table .table thead tr th {font-size: 14px;line-height: 18px;font-weight: bold;color: #8C97A2;}
.metro-table .table tbody tr td {font-size: 14px;line-height: 18px;height: 50px;word-break: break-all;}
.metro-table .table tbody tr td span {height: 100%;display: flex;align-items: center;}
.metro-table button.search-button {background: transparent;padding: 0;border: 0;}
.metro-table .table .td-actions button{padding: 0;background-color: transparent;border: none;}
.metro-table .table .td-actions .blue{color: #044284;}
.metro-table .table .td-actions .red{color: #FF4C51;}
.metro-table .table .td-status .status {min-width:95px;display: inline-block !important;padding: 0px 20px;border-radius: 20px;color: #fff;font-weight: 600;height: 26px !important;}
.metro-table .table .td-status .status .lable{justify-content:center;}
.metro-table .table .td-start{width: 200px;}
.metro-table .table .td-actions{width: 200px;}
.metro-table .table .sorting-icons .icon{width:10px;height:10px;font-size:15px;line-height:15px;cursor: pointer;transition:all ease-in-out 0.3s;color:#ccc;}
.metro-table .table .sorting-icons .icon:hover{color:#044284;}
/**/
.swal2-styled.swal2-default-outline:focus {box-shadow: none !important;}
/*customer-engagement*/
.dashboard .customer-engagement .graph-head {display: block;margin: 0 0 8px;}
/**/
.dashboard .progress-section .graph-head { display: block;margin: 0 0 30px;}
/**/
.dashboard .grouping-section{background: linear-gradient(45deg, rgba(4, 66, 132, 0.8), rgba(111, 210, 38, 0.8)) !important;min-height: 400px;}
.dashboard .grouping-section .graph-head { display: block;margin: 0 0 30px;color: #fff !important;position: relative;}
.dashboard .grouping-section .grouping-chart {display: flex;align-items: center; justify-content: space-between;}
.dashboard .grouping-section .grouping-chart .chart-one{width: 60%;}
.dashboard .grouping-section .grouping-chart .chart-data{width: 40%;}
.dashboard .grouping-section .grouping-chart .chart-data .chart-inner-data {margin: 0 0 36px;}
.dashboard .grouping-section .grouping-chart .chart-data .group-tag{font-size: 30px;line-height: 34px;color: #fff;font-weight: bold;display: block;margin: 0 0 10px;}
.dashboard .grouping-section .grouping-chart .chart-data .group-small-tag{font-size: 16px;line-height: 20px;color: #fff;font-weight: normal;display: block;}
/*main-kpi*/
.main-kpi .section-head{margin: 0 0 30px;}
.kvi-overview .activity-tracking canvas {left: 0px !important;top: 0 !important;width: 100% !important;height: auto !important;}
.kvi-overview .activity-tracking{height: 253px;}
/*best-selling-div*/
.best-selling-div .graph-head{ display: block;}
.best-selling-div .echarts-for-react {margin: 0 0 30px; }
.best-selling-div .graph-data .main-tag{font-size: 13px;font-weight: 500;line-height: 17px; display: block;margin: 0 0 5px;}
.best-selling-div .graph-data .bottom-tag{font-size: 12px;font-weight: normal;line-height: 16px; color: #8C97A2;}
.best-selling-div .graph-data ul li {margin: 0 0 28px;}
.best-selling-div .graph-data ul li.blue-mark::marker {color: #044284;}
.best-selling-div .graph-data ul li.green-mark::marker {color: #6FD226;}
.best-selling-div .graph-data ul li.yellow-mark::marker {color: #FFB400;}
.best-selling-div .graph-data ul li.red-mark::marker {color: #FF4C51;}
.best-selling-div .graph-data ul li.grey-mark::marker {color: #8C97A2;}
/************chat box********/
.chat-section{height: calc(100vh - 162px);}
.chat-section .section-head {height: 67px;}
.chat-section .chat-inner {height: calc(100% - 67px);}
/*chat box sidebar*/
.chat-section .chat-side {border-right: 1px solid #E3E7F0;height: 100%;padding: 15px 15px; width: 25%;}
.chat-section .user-search {height: 50px;position: relative;margin-bottom: 15px;}
.chat-section .user-search input {width: 100%;height: 100%;border-radius: 8px;border: 1px solid #E3E7F0;padding: 5px 35px 5px 15px;}
.chat-section .user-search input:focus{outline: 0;border: 1px solid #044284;}
.chat-section .user-search input:focus ~ .search-icon {color: #044284;}
.chat-section .user-search .search-icon{position: absolute;top: 50%;transform: translateY(-50%);right: 10px; color: #E3E7F0;cursor: pointer;background: transparent;border:none;padding: 0;}
.chat-section .user-search .search-icon:hover{color: #044284;}
/**/
.chat-box-section {border: 1px solid #E3E7F0;border-radius: 8px;height: 100%;}
.chat-box-section .rce-container-citem {margin: 0px 0 15px;padding: 15px 10px;border-radius: 5px;cursor: pointer;}
.chat-box-section .rce-container-citem.active{background: #E3E7F0 ;}
.chat-box-section .rce-container-citem:hover{background: #E3E7F0 ;}
.chat-box-section .chat-sidebar {height: calc(100% - 70px);overflow-y: auto;}
.chat-box-section .chat-sidebar .chat-side-box{position: relative;}
.chat-box-section .chat-sidebar .chat-side-box button {background-color: transparent;border: none;padding: 0;position: absolute;bottom: 18px;right: 15px;font-size: 12px;visibility: hidden; transition: all ease-in-out 0.3s;}
.chat-box-section .chat-sidebar .chat-side-box:hover button {visibility: visible; transition: all ease-in-out 0.3s;}
.chat-box-section .rce-citem {display: flex;align-items: center;}
.chat-box-section .rce-citem-avatar {width: 44px;height: 44px;border-radius: 50%;overflow: hidden;}
.chat-box-section .rce-citem-avatar .rce-avatar-container {width: 100%;height: 100%;}
.chat-box-section .rce-citem-avatar .rce-avatar-container img{width: 100%;height: 100%;object-fit: cover;}
.chat-box-section .rce-citem-body {margin-left: 12px;width: calc(100% - 56px);}
.chat-box-section .rce-citem-body--top {display: flex;align-items: center;justify-content: space-between;}
.chat-box-section .rce-citem-body--top-title {font-size: 16px;font-weight: 600;width: 135px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.chat-box-section .rce-citem-body--top-time {font-size: 13px;}
.chat-box-section .rce-citem-body--bottom {display: flex;align-items: center;justify-content: space-between;max-width: calc(100% - 25px);}
.chat-box-section .rce-citem-body--bottom-title {max-width: calc(100% - 52px);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.chat-box-section .rce-citem-body--bottom-status span {min-width: 25px;height: 25px;max-width: 42px;background: blue;border-radius: 8px;display: flex;justify-content: center;align-items: center;color: #fff;padding: 5px;margin-left: 10px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;font-size: 12px;}
/**/
.chat-box-section .username {height: 50px;border-bottom: 1px solid #E3E7F0;display: flex;align-items: center;padding: 0px 20px;font-size: 18px;font-weight: 600;color: #044284;}
.chat-box-section .username .user-image{width: 30px;height: 30px;border-radius: 50%;overflow: hidden;}
.chat-box-section .username .user-image img{width: 100%;height: 100%;object-fit: cover;}
/*chat box message area*/
.chat-box-section .rce-container-mlist.message-list {padding: 30px 0px;height: calc(100% - 120px);overflow-y: auto;}
.chat-box-section .rce-container-mbox {margin: 0 0 20px;padding: 0px 20px;}
.chat-box-section .rce-mbox {display: table;background: #044284;padding: 12px 20px 12px 20px;border-radius: 8px;position: relative;color: #fff;max-width: 500px;min-width: 150px;}
.chat-box-section .rce-mbox.rce-mbox-right {background: #E3E7F0;color: #000;margin-right: 0;margin-left: auto;}
.chat-box-section .rce-mbox-title.rce-mbox-title {font-size: 16px;font-weight: bold;line-height: 20px;margin: 0 0 5px;}
.chat-box-section .rce-mbox-text.left {font-size: 14px;font-weight: normal;line-height: 28px;}
.chat-box-section .rce-mtlink {text-decoration: underline;font-size: 14px;font-weight: normal;line-height: 28px;cursor: pointer;}
.chat-box-section .rce-mbox-left-notch {position: absolute;left: -14px;top: 0;width: 20px;fill: #044284;}
.chat-box-section .rce-mbox-right-notch {position: absolute;right: -14px;top: 0;width: 20px;fill: #E3E7F0;}
/**/
.chat-box-section .rce-mbox-text {margin: 0 0 10px;padding-right: 40px;}
.chat-box-section .rce-mbox-time.non-copiable{display: flex;justify-content: end;align-items: center;font-size: 13px;line-height: 16px;}
.chat-box-section .rce-mbox-time.non-copiable .rce-mbox-status{width: 16px;height: 16px;margin-right: 7px;}
.chat-box-section .rce-mbox-time.non-copiable .rce-mbox-status svg{height: 100%;width: 100%;display: block;}
.chat-box-section .empty {height: 105px;height: calc(100% - 120px);display: flex;align-items: center;padding: 0px 20px;font-size: 18px;font-weight: 600;color: #044284;justify-content: center;}
.chat-box-section .empty span {background: #dee2e6;color: #ffffff;padding: 10px 15px;border-radius: 10px;text-align: center;}
/**/
.chat-box-section .rce-mbox-forward {color: #FF4C51;position: absolute;right: 16px;display: none;cursor: pointer;}
.chat-box-section .rce-mbox:hover .rce-mbox-forward {display: block;}
/*chat input */
.chat-box-section .message-area {height: 100%;flex: 1;}
.chat-box-section .message-area .write-message {height: 70px;width: 100%;padding: 10px;border-top: 1px solid #E3E7F0;}
.chat-box-section .message-area .write-message input{width: calc(100% - 110px);height: 50px;resize: none;border: 1px solid #E3E7F0;border-radius: 6px;padding: 5px 10px;}
.chat-box-section .message-area .write-message input:focus{border: 1px solid #044284;}
.chat-box-section .message-area .write-message input:focus-visible{outline: 0 !important;}
.chat-box-section .message-area .write-message .blue-button {width: 100px;height: 50px;margin-left: 10px;}
.chat-box-section .message-area .write-message .disable-button {width: 100px;height: 50px;margin-left: 10px;}
/**/
.discalimer {background: #8dc4ff;border: 1px solid #044284;border-radius: 8px;padding: 15px;margin: 0 0 30px;}
.discalimer .title{color: #044284;display: block;font-size: 20px;font-weight: 600;line-height: 24px;margin: 0 0 15px;}
/* .discalimer .deccription{} */
/*notifications modal*/
.noti-modal{width: 0;visibility: hidden;position: absolute;z-index: 2;}
.noti-modal.show {visibility: visible;width: 500px;right: -100px;top: calc(100% + 20px);background: #044284;border-radius: 8px;z-index: 2;color: #fff;}
.noti-modal:before {content: "";position: absolute;border-left: 15px solid transparent;border-right: 15px solid transparent;border-bottom: 15px solid #044284;top: -14px;right: 97px;}
.noti-modal .noti-tag {font-size: 20px;line-height: 24px;font-weight: 600;}
.noti-modal .no-noti-tag {font-size: 20px;line-height: 24px;font-weight: 600;padding: 30px 0px;}
.noti-modal .mark-link button {background: transparent;border: none;padding: 0;color: #fff;text-decoration: underline;font-size: 16px;line-height: 20px;}
.noti-modal .mark-link button:hover {color: #8C97A2}

.noti-modal .title {padding: 20px 15px;border-bottom: 1px solid #8C97A2;}
.noti-modal .body {padding: 15px;max-height: 335px;overflow-y: auto;}
.noti-modal .notifications {display: flex;align-items: end;margin: 0 0 5px;padding: 8px 18px;border-radius: 8px;cursor: pointer;}
.noti-modal .noti-footer {padding: 20px 15px;border-top: 1px solid #8C97A2;}
.noti-modal .notifications.active {background: #FCEA04;color: #000;}
.noti-modal .notifications:last-of-type{margin: 0;}
.noti-modal .notifications .noti-image {width: 35px;height: 35px;border-radius: 50%;overflow: hidden;margin-right: 15px;}
.noti-modal .notifications .noti-image img{width: 100%; height: 100%; object-fit: cover;}
.noti-modal .notifications .noti-data-read{width: 75px;margin-left: 10px;}
.noti-modal .notifications .noti-data-read a {color: rgba(4, 66, 132, 0.7);text-decoration: none;cursor: pointer;font-size: 12px;}
.noti-modal .notifications .noti-data-read a:hover {color: rgba(4, 66, 132, 1);}
.noti-modal .notifications .noti-data-read {display: none;}
.noti-modal .notifications .noti-data {width: calc(100% - 50px); padding-left: 3px;}
.noti-modal .notifications.notification-active .noti-data-read {display: block;}
.noti-modal .notifications .noti-first{width: 100%;display: flex; align-items: center;}
.noti-modal .notifications.notification-active .noti-first {width: calc(100% - 85px);display: flex;align-items: center;}
.noti-modal .notifications .noti-data .noti-title {display: block;font-size: 16px;line-height: 20px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
.noti-modal .notifications .noti-data .noti-title  .title-name{font-weight: 600;}
.noti-modal .notifications .noti-data .noti-description {font-size: 14px;line-height: 18px;color: #8C97A2;display: inline-block;/* white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 100%;display: inline-block; */}
.noti-modal .notifications .noti-data .noti-mark-as-read {font-size: 14px;line-height: 18px;color: #8C97A2;/* white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 100%;display: inline-block; */}
.noti-modal .notifications.active .noti-data .noti-description{color: #4d5359;}
/**/
.noti-modal .all-noti-button {text-align: center;padding: 0px;}
.noti-modal .all-noti-button a{font-size: 18px;line-height: 22px;text-decoration: none;color: #fff;}
.noti-modal .all-noti-button a:hover{color: #FCEA04;}
.noti-modal .all-noti-button button{font-size: 18px;line-height: 22px;text-decoration: none;color: #fff;background: transparent;border: none;padding: 0;}
.noti-modal .all-noti-button button:hover{color: #FCEA04;}
/**/
.image-frame{background-color: #1e1e1e;height: 278px;position: relative;width:278px;-webkit-box-shadow: 4px 7px 14px rgba(50, 50, 50, 0.44);-moz-box-shadow: 4px 7px 14px rgba(50, 50, 50, 0.44);box-shadow: 4px 7px 14px rgba(50, 50, 50, 0.44);background: -moz-linear-gradient(-45deg, #044284 52%, #FCEA04 20%, #044284 100%);background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#044284), color-stop(20%,#FCEA04), color-stop(100%,#044284));background: -webkit-linear-gradient(-45deg, #044284 52%,#FCEA04 20%,#044284 100%);background: -o-linear-gradient(-45deg, #044284 52%,#FCEA04 20%,#044284 100%);background: -ms-linear-gradient(-45deg, #044284 52%,#FCEA04 20%,#044284 100%);background: linear-gradient(135deg, #044284 52%,#FCEA04 20%,#044284 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#044284', endColorstr='#044284',GradientType=1 );}
.image-frame .innerBorder {background: #dfe7f4;left: 16px;position: absolute;padding: 12px; top: 16px;right:16px;bottom:16px;-webkit-box-shadow: inset 5px 5px 12px rgba(50, 50, 50, 0.44);-moz-box-shadow: inset 5px 5px 12px rgba(50, 50, 50, 0.44);box-shadow: inset 5px 5px 12px rgba(50, 50, 50, 0.44);overflow: hidden;display: flex;align-items: center;justify-content: center;}
.image-frame .innerBorder img{width: 100%;height: 100%;object-fit: fill;}
/**/
.dropdown .dropdown-menu{padding: 5px 0px;}
.dropdown .dropdown-menu .dropdown-item{margin: 0 0 5px;}
.dropdown .dropdown-menu .dropdown-item:last-of-type{margin: 0;}
.dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:focus, .dropdown .dropdown-menu .dropdown-item:active {background-color: #044284;color: #fff;}
/**/
.users-section .react-select .react-select__control {height: 43px; background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: 8px;text-align: start;}
.users-section .react-select .react-select__input-container{height: 38px;}
.users-section .react-select .react-select__input-container input{box-shadow: none !important;height: auto; margin-left: 0 !important;}
.users-section .react-select .react-select__input-container input:focus{box-shadow: none !important;}
/**/
.password-div {position: relative;margin-left: 20px !important;width: calc(100% - 170px);}
.password-div input {width: 100% !important;margin-left: 0px !important;}
.password-div .password-eye-icon{position: absolute;top: 50%;transform: translateY(-50%);right: 20px;}
.notification-active {background-color: #ffffff;}
.notification-active .noti-title {color: #044284;}
.mobile-logo {width: 150px;display: block;margin: 0 auto;}
.mobile-logo img {width: 100%;height: 100%;}
.mobile-logo-area {padding: 0px 12px 0px 50px;}
.mobile-logo-wrapper {background-color: #044284;padding: 15px 0px;border-radius: 5px;margin-bottom: 20px;}
.mobile-logo-area {display: none;}
.approve-info-wrapper {display: flex;justify-content: space-between;margin-bottom: 35px;}
.phone-input-wrapper {position: relative;width: calc(100% - 170px);margin-left: 20px;}
.phone-input-wrapper .PhoneInputCountry {position: absolute;left: 15px;top: 50%;transform: translateY(-50%);}
.phone-input-wrapper input {padding: 0.375rem 0.75rem 0.375rem 60px;width: 100%;margin-left: 0 !important;}
.login-loader-wrapper {position: relative;}
.loader-login {border: 6px solid #ccc;border-radius: 50%;border-top: 6px solid #658bb3;border-bottom: 6px solid #658bb3;width: 30px;height: 30px;animation: spin 2s linear infinite;position: absolute;top: 10px;left: 47%;transform: translateX(-50%);}
/**/
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/**/
.users-section .section-checkboxes input{height: 43px;border-radius: 8px;}
.users-section .section-checkboxes input:focus {outline: 0;box-shadow: none;}  
/**/
.multi-select *{transition: none !important;}
.multi-select .dropdown-container {height: 43px;border-radius: 8px !important;}
.multi-select .dropdown-container .dropdown-heading {height: 43px !important;}
.multi-select .dropdown-content input {height: auto;}
.multi-select .dropdown-content .select-item:hover {background: #F4F7FC;color: #000;}
.multi-select .dropdown-content .select-item.selected{background: #044284;color: #fff;}
.multi-select .dropdown-content .select-item.selected:hover{background: #044284;color: #fff;}
/**/
.swal2-popup.swal2-modal.swal2-show {background: #044284;border-radius: 8px;color: #fff;max-width: 700px;width: 100%;}
.swal2-html-container > div{display: flex;align-items: start !important;}
.swal2-html-container > div strong{width: 90px;display: inline-block;}
.swal2-html-container > div span {width: calc(100% - 90px);display: inline-block;text-align: justify;max-height: 500px;padding-right: 12px;}
/**/
.persmissions-check-main {margin: 0 0 25px;}
.persmissions-check-main .title-label {display: block;margin: 10px 0 10px;font-weight: 500;font-size: 16px;line-height: 20px;color: #000;width: auto;}

/* responsive */
@media only screen and (max-width:1919px){

}
@media only screen and (max-width:1799px){
    .dashboard .grouping-section .grouping-chart .chart-one .echarts-for-react {height: 260px !important;width: 260px !important;}
    /**/
    .chat-section .chat-side {width: 30%;}
}
@media only screen and (max-width:1599px){
    .content-area {width: calc(100% - 250px);}
    .dashboard .graph-head {font-size: 20px;line-height: 24px;}
    .dashboard .sub-heading-medium {font-size: 15px;line-height: 19px;}
    .dashboard .activity-tracking canvas {top: -13px !important;width: 221px !important;height: 170px !important;}
    .dashboard .response-time canvas {width: 250px !important;}
    .kvi-overview .activity-tracking canvas {left: 0px !important;top: 0 !important;width: 100% !important;height: auto !important;}
    /**/
    .chat-section .chat-side {width: 32%;}
}
@media only screen and (max-width:1399px){
    .dashboard .incentive-earned {margin: 0 0 30px;}
    .dashboard .metro-table{margin: 0 0 30px;}
    .dashboard .responsive-margin{margin-bottom: 30px;}
    .kvi-overview .clip-board {min-height: 253px;}
    /**/
    .noti-modal .noti-tag {font-size: 18px;line-height: 22px;}
    /*chat-section*/
    .chat-section .chat-side {width: 38%;}
    .chat-box-section .rce-citem-avatar {width: 35px;height: 35px;}
    .chat-box-section .rce-citem-body {margin-left: 10px;width: calc(100% - 45px);}
    .chat-box-section .rce-citem-body--top-title {font-size: 14px;}
    .chat-box-section .rce-citem-body--top-time {font-size: 11px;}
    .chat-box-section .rce-citem-body--bottom-title {font-size: 12px;}
    .chat-box-section .rce-mbox {padding: 10px 16px;font-size: 14px;}
    .chat-box-section .rce-container-mbox {margin: 0 0 12px;}
}
@media only screen and (max-width:1199px){
    .content-area {width: calc(100% - 210px);}
    .kvi-overview .clip-board {min-height: auto;margin: 0 0 30px;}
    /**/
    .add-form label {font-size: 14px;line-height: 18px;width: 134px;}
    .add-form .react-select {margin-left: 20px !important;width: calc(100% - 154px);}
    .add-form input {margin-left: 20px !important;width: calc(100% - 154px);}
    .add-form textarea {margin-left: 20px !important;width: calc(100% - 154px);}
    .password-div input {width: 100% !important;margin-left: 0px !important;}
    .password-div {margin-left: 20px !important;width: calc(100% - 154px);}
    .phone-input-wrapper input {margin-left: 0px !important;}
    .phone-input-wrapper {width: calc(100% - 154px);}
    /**/
    .chat-section .chat-side {width: 41%;}
    .chat-box-section .rce-citem-body--top-title {width: 90px;}
}
@media only screen and (max-width:991px){
    .content-area {width: 100%;overflow-x: hidden;}
    .kvi-overview .activity-tracking {height: auto;}
    .main-kpi .graph-area, .activity-tracking .graph-area, .progress-section .graph-area{width: 100%;overflow-x: auto;}
    .main-kpi .graph-area .echarts-for-react, .activity-tracking .graph-area > div,  .progress-section .graph-area .echarts-for-react {width: 700px !important;} 
    /**/
    .chat-section .chat-side {width: 43%;}
    .activity-tracking .graph-area {width: 200px;overflow: unset;}
    .activity-tracking .graph-area > div {margin-left: auto;width: 100% !important;}
}
@media only screen and (max-width:767px){
    .dashboard .incentive-earned {flex-direction: column;}
    .dashboard .incentive-earned .graph-area {width: 100%;}
    .dashboard .incentive-earned .graph-report {width: 100%;}
    .metro-table .table {min-width: 650px;table-layout: fixed;}
    /**/
    .noti-modal.show {width: 420px;}
    /**/
    .chat-section .chat-side {width: 100%;}
    .chat-box-section .block-div{display: block;}
    .chat-box-section .none-div{display: none;}
    .chat-box-section .username .back-icon{cursor: pointer;width: 15px;}
    .chat-box-section .username .username-area{width: calc(100% - 35px);margin-left: 20px;}
    .chat-box-section .username .user-name {margin-left: 10px !important;max-width: calc(100% - 25px);overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
    /**/
    .chat-box-section .rce-citem-body--top-title {width: 50%;}
    /**/
    .noti-modal.show {right: -165px;}
    .noti-modal:before {right: 162px;}
}
@media only screen and (max-width:575px){
    .dashboard .grouping-section .grouping-chart {flex-direction: column;}
    .dashboard .grouping-section .grouping-chart .chart-one {width: 100%;}
    .dashboard .grouping-section .grouping-chart .chart-one .echarts-for-react {margin: 0 auto;}
    .dashboard .grouping-section .grouping-chart .chart-data {width: 100%;}
    /**/
    .image-frame {height: 228px;width: 228px;}
    .input-file-btn-holder label {width: 228px !important;}
    /**/
    .noti-modal.show {width: 420px;right: -90px;}
    .noti-modal:before {right: 87px;}
    .mobile-logo-area {display: block;}
    .sidebar .logo {display: none;}
    .add-form .add-form-buttons {margin-top: 20px;}
    /**/
    .noti-modal.show {right: -185px;}
    .noti-modal:before {right: 183px;}
}
@media only screen and (max-width:479px){
    .content-area {padding: 8px 0px 30px;}
    .dashboard .sales-performance {justify-content: center;flex-direction: column;align-items: center;}
    .dashboard .response-inner {flex-direction: column;align-items: center !important;}
    .dashboard .activity-inner{flex-direction: column;align-items: center !important;}
    .dashboard .grouping-section .grouping-chart .chart-data .group-tag {font-size: 22px;line-height: 28px;}
    .dashboard .grouping-section .grouping-chart .chart-data .group-small-tag {font-size: 14px;line-height: 18px;}
    .dashboard .grouping-section .grouping-chart .chart-one .echarts-for-react {height: 240px !important;width: 240px !important;}
    .check-container {font-size: 12px;}
    /**/
    .input-file-btn-holder {margin-left: 0 !important;}
    /**/
    .add-form .add-form-buttons > div {flex-direction: column;}
    .add-form .react-select {margin-left: 0px !important;width: 100%;}
    .add-form input {margin-left: 0px !important;width: 100%;}
    .add-form textarea {margin-left: 0px !important;width: 100%;}
    .password-div {margin-left: 0px !important;width: 100%;}
    /**/
    .add-form .form-data {flex-direction: column;align-items: start !important;}
    .add-form .react-select, .add-form input, .add-form textarea {margin-left: 0px !important;}
    .phone-input-wrapper {width: 100%;margin-left: 0px;}
    /**/
    .add-form .add-form-buttons > div .blue-button {margin-bottom: 10px;}
    .add-form .add-form-buttons > div .blue-outline-button {margin-left: 0px !important;}
    /**/
    .noti-modal.show {right: 0px;left: 0;width: auto;}
    .noti-modal .title {flex-direction: column;}
    .noti-modal .notifications {flex-direction: column;text-align: center;align-items: center;}
    .noti-modal .notifications .noti-data {width: 100%;}
    .noti-modal .notifications.notification-active .noti-data {width: 100%;}
    .noti-modal:before {right: 100px;}
    /**/
    .check-container {padding-left: 28px;}
    .add-form .permission-check-all {flex-direction: column;align-items: stretch !important;}
    /**/
    .login-link-parent{flex-direction: column;}
    /*chat-box-section*/
    .chat-box-section .rce-citem-body--top {align-items: start;flex-direction: column;}
    .chat-box-section .rce-citem-body--top-title {width: 100%;}
    .chat-box-section .rce-citem-body--bottom-title {max-width: 100%;width: 100%;}
    .chat-box-section .message-area .write-message {flex-direction: column;height: 100px;}
    .chat-box-section .message-area .write-message input {width: 100%;height: 40px;margin: 0 0 10px;}
    .chat-box-section .message-area .write-message .blue-button {width: 100%;height: 30px;margin-left: 0px;}
    .chat-box-section .rce-container-mlist.message-list {height: calc(100% - 150px);}
    /**/
    /* .noti-modal.show {right: -181px;}
    .noti-modal:before {right: 178px;} */
    .noti-modal:before {right: 127px;}
    /**/
    .input-file-btn-holder {margin-left: 0px !important;width: 100%;}
    .input-file-btn-holder label {margin: 0 auto 20px;}
    .input-file-btn-holder .image-frame{margin: 0 auto;}
    /**/
    .noti-modal .notifications .noti-first {flex-direction: column;}
    .noti-modal .notifications .noti-image {margin: 0 auto 5px;}
    .noti-modal .notifications .noti-data-read {width: 100%;margin-left: 0px;}
    .noti-modal .notifications.notification-active .noti-first {width: 100%;}
}
@media only screen and (max-width:319px){
    .dashboard .grouping-section .grouping-chart .chart-one .echarts-for-react {height: 210px !important;width: 210px !important;}
    /**/
    .image-frame {height: 206px;width: 206px;}
    .input-file-btn-holder label {width: 206px !important;}
}