.search-bar{margin: 0 0 30px;padding: 0px 12px;}
.search-bar .search-area {position: relative;height: 72px;width: 100%;border: none;box-shadow: 0px 0px 8px rgb(0 0 0 / 20%);padding: 10px 40px 10px 40px;border-radius: 8px;background-color: #fff;display: flex;justify-content: end;align-items: center;}
/**/
.search-bar .search-area .noti-dropdown{margin-right: 30px;position: relative;}
.search-bar .search-area .noti-dropdown .blue-dot {width:16px;height: 16px;background: #044284;border-radius: 50%;position: absolute;right: -10px;top: 30%;transform: translateY(-50%);font-size: 10px;font-weight: 600;display: flex;align-items: center;justify-content: center;}
.search-bar .search-area .noti-name {font-size: 18px;line-height: 22px;text-overflow: ellipsis;overflow: hidden;max-width: 150px;margin-left: 10px;white-space: nowrap;display: block;font-weight: 600;color: #044284;}
.search-bar .search-area .noti-chat{margin-right: 30px;position: relative;}
.search-bar .search-area .noti-chat .blue-dot {width: 16px;height: 16px;background: #044284;border-radius: 50%;position: absolute;right: -10px;top: 30%;transform: translateY(-50%);font-size: 10px;font-weight: 600;display: flex;align-items: center;justify-content: center;}
.noti-data-active {position: relative;}
.noti-data-active::before {content: '';position: absolute;width: 6px;height: 6px;background-color: #00cb6d;border-radius: 50%;left: -12px;top: 8px;}
.search-bar .search-area #notifictaions {background: none;border: none;padding: 0;}
.search-bar .search-area #notifictaions::after{display: none;}
/**/
/* .search-bar .search-area .profile-dropdown{border: 1px solid #E3E7F0;width: 50px;height: 50px;border-radius: 50%;} */
.search-bar .search-area #profile-pic {background: none;border: none;padding: 0;display: flex;align-items: center;color: #044284;}
.search-bar .search-area #profile-pic img{border-radius: 50%;overflow: hidden;width: 35px;height: 35px;object-fit: cover;}
.search-bar .search-area #profile-pic::after{--bs-btn-color: #044284;}
/* responsive */
@media only screen and (max-width:1199px){
}
@media only screen and (max-width:991px){
    .search-bar {padding: 0px 12px 0px 40px;}
}
@media only screen and (max-width:767px){

}
@media only screen and (max-width:575px){
    .search-bar .search-area .noti-name {max-width: 115px;}
    .search-bar {padding: 0px 12px 0px 12px;}
}
@media only screen and (max-width:479px){
    .search-bar .search-area {padding: 10px 12px 10px 12px;}
    .search-bar .search-area .noti-chat {margin-right: 20px;}
    .search-bar .search-area .noti-dropdown {margin-right: 20px;}
    .search-bar .search-area .noti-name {max-width: 100px;font-size: 14px;line-height: 18px;}
     /**/
     .search-bar .search-area .noti-dropdown {position: static;}
     .search-bar .search-area #notifictaions {position: relative;}
}
@media only screen and (max-width:479px){
    .search-bar .search-area .noti-name {max-width: 75px;}
}