.sidebar{width: 292px;height: 100vh;background-color: #044284;padding: 30px 0px 10px 0px;position: relative;}
.sidebar .logo {width: 170px;height: 67px;padding-left: 0;margin: 0 auto;}
.sidebar .main-head {position: relative;height: 44px;margin: 0 0 20px;}
.sidebar .main-head::after{content: "";position: absolute;background-color: #4299F5;width: 100%;height: 1px;left: -30px;top: 45%;transform: translateY(-50%);}
.sidebar .main-head span{color: #4299F5;padding: 10px 10px 10px 10px;background-color: #044284;position: absolute;transform: translateX(-10px);z-index: 1;font-size: 14px;font-weight: normal;line-height: 18px;}
/*menu*/
.sidebar .main-menu {padding-left: 30px;height: calc(100vh - 107px);overflow-x: hidden;overflow-y: auto;}
.sidebar .menu {margin: 0px 0 25px;}
.sidebar .menu ul{padding: 0;list-style: none;}
.sidebar .menu ul li{margin: 0 0 30px;position: relative;}
.sidebar .menu ul li a{display: flex;align-items: center;color: #8C97A2;text-decoration: none;}
.sidebar .menu ul li.active a{color: #FCEA04;}
.sidebar .menu ul li:hover a{color: #FCEA04;}
.sidebar .menu ul li a .menu-img {width: 24px;height: 24px;display: inline-block;}
.sidebar .menu ul li a .menu-img svg {width: 100%;height: 100%;}
.sidebar .menu ul li a .menu-text {max-width: calc(100% - 34px);margin-left: 10px;font-size: 18px;line-height: 22px;font-weight: normal;display: inline-block;margin-top: 5px;}
.sidebar .menu ul li a .menu-img .grey{display: block;}
.sidebar .menu ul li a .menu-img .yellow{display: none;}
.sidebar .menu ul li.active a .menu-img .grey, .sidebar .menu ul li:hover a .menu-img .grey{display: none;}
.sidebar .menu ul li.active a .menu-img .yellow, .sidebar .menu ul li:hover a .menu-img .yellow{display: block;}
/*signout*/
.sidebar .signout-section {border-top: 1px solid #4299F5;padding: 30px 0px 30px 30px;position: static;bottom: 0;width: 100%;left: 0;}
.sidebar .signout-section a{display: flex;align-items: center;color: #8C97A2;text-decoration: none;}
.sidebar .signout-section.active a{color: #FCEA04;}
.sidebar .signout-section a:hover{color: #FCEA04;}
.sidebar .signout-section a .menu-img {width: 24px;height: 24px;display: inline-block;}
.sidebar .signout-section a .menu-img .grey{display: block;}
.sidebar .signout-section a .menu-img .yellow{display: none;}
.sidebar .signout-section a:hover .menu-img .grey{display: none;}
.sidebar .signout-section a:hover .menu-img .yellow{display: block;}
.sidebar .signout-section a .menu-text {max-width: calc(100% - 34px);margin-left: 10px;font-size: 18px;line-height: 22px;font-weight: normal;display: inline-block;margin-top: 5px;}
/*sub menu*/
.sidebar .sub-menu{padding: 0px 15px !important;}
.sidebar .sub-menu-button .tab-icon { width: 12px;height: 12px;border-left: 2px solid #8C97A2;border-bottom: 2px solid #8C97A2; transform: rotate(-45deg) translateY(-50%);transition: all ease-in-out 0.3s;position: absolute;right: 9%;top: 38%;}
.sidebar .open.sub-menu-button .tab-icon {transform: rotate(135deg);color: #FCEA04;transition:  all ease-in-out 0.3s;top: 45%;border-left: 2px solid #FCEA04;border-bottom: 2px solid #FCEA04;}
.sidebar .open.sub-menu-button .menu-text{color: #FCEA04;}
.sidebar .open.sub-menu-button .menu-img .yellow{display: block;}
.sidebar .open.sub-menu-button .menu-img .grey{display: none;}
/**/
.sidebar .sidebar-button {display: none;background: white;border: none;position: absolute;right: -44px;color: #044284;border-radius: 0px 10px 10px 0px;width: 44px;height: 44px;top: 44px;box-shadow: 1px 1px 10px 4px #8181815e;}
.sidebar .sidebar-button .bars-icon{display: inline-block;}
.sidebar .sidebar-button .cross-icon{display: none;}
.sidebar.open .sidebar-button .bars-icon{display: none;}
.sidebar.open .sidebar-button .cross-icon{display: inline-block;font-size: 20px;}
/* responsive */
@media only screen and (max-width:1599px){
    .sidebar {width: 250px;}
    .sidebar .menu ul li a .menu-img, .sidebar .signout-section a .menu-img {width: 20px;height: 20px;}
    .sidebar .menu ul li a .menu-text {max-width: calc(100% - 30px);margin-left: 10px;font-size: 17px;line-height: 20px;}
    .sidebar .main-menu {padding-left: 20px;}
    .sidebar .signout-section {padding: 30px 0px 30px 20px;}
    .sidebar .signout-section a .menu-text {font-size: 17px;line-height: 20px;}
}
@media only screen and (max-width:1399px){
    .sidebar .logo {height: 50px;width: 136px;}
    /* .sidebar .main-menu {height: calc(100vh - 168px);} */
}
@media only screen and (max-width:1199px){
    .sidebar {width: 210px;}
    .sidebar .main-head span {transform: translateX(0px);}
    .sidebar .menu ul li a .menu-text {font-size: 14px;line-height: 18px;}
    .sidebar .main-menu {padding-left: 15px;}
    .sidebar .signout-section {padding: 30px 0px 30px 15px;}
    .sidebar .logo {width: 120px;}
    .sidebar .signout-section a .menu-text {font-size: 14px;line-height: 18px;}
}
@media only screen and (max-width:991px){
    .sidebar {position: absolute;z-index: 99;left: -210px;}
    .sidebar.open{left: 0px;}
    .sidebar .sidebar-button { display:block;}
}
@media only screen and (max-width: 575px){
    .sidebar .sidebar-button {top: 36px;}
}
@media only screen and (max-width:479px){
    /* .sidebar-button {top: 125px;} */
    .sidebar .sidebar-button {top: 15px;}
}